export default {
  "hljs": {
    "display": "block",
    "overflowX": "auto",
    "padding": "0.5em",
    "background": "#fff",
    "color": "black"
  },
  "xml .hljs-meta": {
    "color": "#c0c0c0"
  },
  "hljs-comment": {
    "color": "#007400"
  },
  "hljs-quote": {
    "color": "#007400"
  },
  "hljs-tag": {
    "color": "#aa0d91"
  },
  "hljs-attribute": {
    "color": "#aa0d91"
  },
  "hljs-keyword": {
    "color": "#aa0d91"
  },
  "hljs-selector-tag": {
    "color": "#aa0d91"
  },
  "hljs-literal": {
    "color": "#aa0d91"
  },
  "hljs-name": {
    "color": "#aa0d91"
  },
  "hljs-variable": {
    "color": "#3F6E74"
  },
  "hljs-template-variable": {
    "color": "#3F6E74"
  },
  "hljs-code": {
    "color": "#c41a16"
  },
  "hljs-string": {
    "color": "#c41a16"
  },
  "hljs-meta-string": {
    "color": "#c41a16"
  },
  "hljs-regexp": {
    "color": "#0E0EFF"
  },
  "hljs-link": {
    "color": "#0E0EFF"
  },
  "hljs-title": {
    "color": "#1c00cf"
  },
  "hljs-symbol": {
    "color": "#1c00cf"
  },
  "hljs-bullet": {
    "color": "#1c00cf"
  },
  "hljs-number": {
    "color": "#1c00cf"
  },
  "hljs-section": {
    "color": "#643820"
  },
  "hljs-meta": {
    "color": "#643820"
  },
  "hljs-class .hljs-title": {
    "color": "#5c2699"
  },
  "hljs-type": {
    "color": "#5c2699"
  },
  "hljs-built_in": {
    "color": "#5c2699"
  },
  "hljs-builtin-name": {
    "color": "#5c2699"
  },
  "hljs-params": {
    "color": "#5c2699"
  },
  "hljs-attr": {
    "color": "#836C28"
  },
  "hljs-subst": {
    "color": "#000"
  },
  "hljs-formula": {
    "backgroundColor": "#eee",
    "fontStyle": "italic"
  },
  "hljs-addition": {
    "backgroundColor": "#baeeba"
  },
  "hljs-deletion": {
    "backgroundColor": "#ffc8bd"
  },
  "hljs-selector-id": {
    "color": "#9b703f"
  },
  "hljs-selector-class": {
    "color": "#9b703f"
  },
  "hljs-doctag": {
    "fontWeight": "bold"
  },
  "hljs-strong": {
    "fontWeight": "bold"
  },
  "hljs-emphasis": {
    "fontStyle": "italic"
  }
};